import React from "react";
import "./header.css";

const Header = () => {
    const phoneNumber = "+94764287002";

    const handlePhoneClick = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return(
        <div className="header py-2">
            <div className="container">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="d-flex ">
                            <div className="phone mr-3">
                                <span onClick={handlePhoneClick}>Call: {phoneNumber}</span>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Header;