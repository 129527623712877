import React, { useContext, useEffect } from 'react';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Container } from 'react-bootstrap';
import { ShopContext } from '../context/Shop-Context';
import ImageCarousel from '../components/ImageCarousel';

const Home = () => {
  const { homeImages } = useContext(ShopContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid>
      <Header />
      <Navigation activePath='/' />
      <div className='main'>
        {homeImages ? 
          <ImageCarousel images={homeImages} />
        : null}
      </div>
      <Footer />
    </Container>
  )
}

export default Home
