import React, { createContext, useState, useEffect } from 'react';
import { getCollections, getShippingCharges, getProducts, getContactDetails, getHomeImages } from '../helpers/GetData';

export const ShopContext = createContext(null);

const LOCAL_STORAGE_KEY = 'shoppingCart';

const ShopContextProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [collections, setCollections] = useState([]);
  const [shippingCharges, setShippingCharges] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);
  const [homeImages, setHomeImages] = useState([]);
  const [cartItems, setCartItems] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productData = await getProducts();
        setProducts(productData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setCartItems((prev) => {
      const newCartItems = { ...prev };

      if (products.length > 0) {
        for (let i = 1; i <= products.length; i++) {
          if (!(i in newCartItems)) {
            newCartItems[i] = 0;
          }
        }
      }

      return newCartItems;
    });
  }, [products]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionData = await getCollections();
        setCollections(collectionData);
      } catch (error) {
        console.error('Error fetching collections:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const shippingData = await getShippingCharges();
        setShippingCharges(shippingData);
      } catch (error) {
        console.error('Error fetching shipping charges:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contactData = await getContactDetails();
        setContactDetails(contactData);
      } catch (error) {
        console.error('Error fetching shipping charges:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const homeImagesData = await getHomeImages();
        const urls = homeImagesData.map(data => data.homeImage);
        setHomeImages(urls);
      } catch (error) {
        console.error('Error fetching shipping charges:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Load cartItems from local storage only once when component mounts
    const storedCartItems = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
    setCartItems((prev) => ({
      ...prev,
      ...storedCartItems,
    }));
  }, []);

  useEffect(() => {
    // Update local storage whenever cartItems change
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cartItems));
    console.log(cartItems);
  }, [cartItems]);


  

  const addToCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
  };

  const updateCartItemCount = (newAmount, itemId) => {
      setCartItems((prev) => ({ ...prev, [itemId]: newAmount }));
  }

  const clearCartItemCount = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: 0 }));
  }

  const clearCart = () => {
    setCartItems({});
  };
  
  
  const contextValue = { products, loading, cartItems, collections, shippingCharges, contactDetails, homeImages,
                          addToCart, removeFromCart, updateCartItemCount, clearCartItemCount, clearCart };


  return (
    <ShopContext.Provider value={contextValue}>{props.children}</ShopContext.Provider>
  );
};

export default ShopContextProvider;

