import React, { useContext, useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Product from '../components/Product';
import "../styles.css";
import "../pages/shop.css";
import { ShopContext } from '../context/Shop-Context';

const Shop = () => {
  const { products, loading } = useContext(ShopContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid>
      <Header />
      <Navigation activePath='/shop' />
      <div className='main'>
        <div className='shop'>
          <h3>All Products</h3>
          {loading ? (
            <div className="text-center mt-5">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div className='row justify-content-center'>
              {products.length === 0 ? (
                <p>No data available</p>
              ) : (
                products.map(product => (
                  <div key={product.productId} className='col-sm-6 col-md-4 col-lg-3'>
                    <Product data={product} />
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </Container>
  )
}

export default Shop
