import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ShopContext } from '../context/Shop-Context';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { getProductById, getProductImagesByProductId } from '../helpers/GetData';
import "../styles.css";
import "../pages/productDisplay.css";

const ProductDisplay = () => {
    const { cartItems, addToCart, removeFromCart, updateCartItemCount } = useContext(ShopContext);
    const location = useLocation();
    const productId = location.state.productId;
    const [product, setProduct] = useState(null);
    const [productImages, setProductImages] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() =>{
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const productData = await getProductById(productId);
            setProduct(productData);
        };

        fetchData();
    }, [productId]);

    useEffect(() => {
        if (product) {
            setSelectedImage(product.productImage);
        }
    }, [product]);

    useEffect(() => {
        const fetchData = async () => {
            const productData = await getProductImagesByProductId(productId);
            setProductImages(productData);
        };

        fetchData();
    }, [productId]);

    const handleBlur = (e) => {
        const inputValue = e.target.value.trim();
        if (inputValue === '') {

            updateCartItemCount(1, productId);
        }
    }
    const handleChange = (e) => {
        const inputValue = e.target.value.trim();
        if (inputValue !== '' && !isNaN(inputValue)) {
            updateCartItemCount(Number(inputValue), productId);
        }
        else {
            updateCartItemCount('', productId);
        }

    }

    const handleImageClick = (image) => {
        setSelectedImage(image);
    }

    const getSpecifications = (specifications) => {
        return specifications.split(',').filter(specification => specification.trim() !== '');
    };

    return (
        <Container fluid>
            <Header />
            <Navigation />
            <div className='main'>
                <div className='row'>
                    {product ? <>
                        <div className='col-12 col-md-6 product-images'>
                            <div className='large-img'>
                                <img src={selectedImage} alt='Product' />
                            </div>
                            <div className='small-img'>
                                <img src={product.productImage} alt='Image1'
                                    className={selectedImage === product.productImage ? 'selected' : ''}
                                    onClick={() => handleImageClick(product.productImage)} />
                                {productImages && productImages.length > 0 ? productImages.map((image) => (
                                    <img src={image.image} alt={`Product ${image.productId}`}
                                        className={selectedImage === image.image ? 'selected' : ''}
                                        onClick={() => handleImageClick(image.image)} />
                                )) : null}

                            </div>
                        </div>
                        <div className='col-12 col-md-6 product-details'>
                            <h4>{product.productName}</h4>
                            {product.salePrice ? 
                                <p>
                                    <b>Rs.<del>{product.price}</del> {product.salePrice}</b>
                                    &nbsp;&nbsp;&nbsp;<span className='sale'>Save Rs.{product.price - product.salePrice}</span>
                                </p>
                               :<p><b>Rs.{product.price}</b></p>
                            }
                            <p>{product.description}</p>
                            <hr></hr>
                            {cartItems[productId] > 0 ?
                                <>
                                    <h5>Quantity</h5>
                                    <div className='count-handler'>

                                        <button onClick={() => removeFromCart(productId)}>-</button>
                                        <input value={cartItems[productId]} onChange={(e) => handleChange(e)}
                                            onBlur={(e) => handleBlur(e)} />
                                        <button onClick={() => addToCart(productId)}>+</button>

                                    </div>
                                </>
                                : null
                            }
                            <button className='addtocart-button' onClick={() => addToCart(productId)}>
                                Add to Cart
                            </button>
                            {product.specifications ?
                                <>
                                    <div className='product-spec'>
                                        <h5>Product Specifications</h5>
                                        <ul>
                                            {getSpecifications(product.specifications).map((specification, index) => (
                                                <li key={index}>{specification.trim()}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </> : null
                            }
                        </div>
                    </> : null}
                </div>
            </div>
            <Footer />
        </Container>
    )
}

export default ProductDisplay
