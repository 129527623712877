import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import "./shop.css";
import "./about.css";

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container fluid>
      <Header />
      <Navigation activePath='/about' />
      <div className='main'>
        <div className='about'>
          <h2>About Art Paradise</h2>
          <p className='about-intro'>Discover the magic of handmade at <b>Art Paradise</b>. We're your online haven for
            thoughtful and artistic gifts, bringing you a curated selection of handcrafted delights.</p>
          <h3>Our Passion</h3>
          <p>
            Founded by a dedicated art enthusiast, <b>Art Paradise</b> is a celebration of creativity and the joy
            of giving. We're thrilled to showcase a unique collection of handmade treasures that add a 
            personal touch to any occasion.
          </p>
          <h3>What We Offer</h3>
          <p>
            Explore our range, including charming greeting cards, intricate embroidery hoop art, boho-chic
            macramé, and delightful handmade flower pots, etc. Each piece is a labor of love, created by talented 
            artisans who pour their passion into every detail.
          </p>
          <h3>Why Choose Art Paradise</h3>
          <p>
            We're not just an online store; we're a destination for those who appreciate the art of gifting. Art Paradise
            is committed to making your shopping experience delightful, from the first click to 
            the moment your handcrafted treasure arrives at your door.
          </p>
          <h3>Join the Celebration</h3>
          <p>
            Whether you're celebrating a special moment or just treating yourself, Art Paradise invites 
            you to join our creative journey. Embrace the joy of giving with our unique, handmade creations.
          </p>
          <p className='about-intro'>
            <b>Thank you for choosing Art Paradise. Let's make every gift a masterpiece!</b>
          </p>
        </div>
      </div>
      <Footer />
    </Container>
  )
}

export default About
