import React from 'react';
import { Modal } from 'react-bootstrap';

const OrderPlaceErrorAlert = ({ show, handleClose, errorMessage }) => {
  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton style={{backgroundImage: 'red', color: 'white'}}>
        <Modal.Title>Something Went Wrong!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{errorMessage}</p>
        <p>Thank you for your patience</p>
        <p>Please try again!</p>
      </Modal.Body>
    </Modal>
  );
};

export default OrderPlaceErrorAlert;