import React, { useContext } from 'react';
import { ShopContext } from '../context/Shop-Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const CartItem = (props) => {
    const { productId, productName, price, salePrice, productImage } = props.data;
    const { cartItems, addToCart, removeFromCart, updateCartItemCount, clearCartItemCount } = useContext(ShopContext);

    const handleBlur = (e) => {
        const inputValue = e.target.value.trim();
        if (inputValue === '') {

            updateCartItemCount(1, productId);
        }
    }
    const handleChange = (e) => {
        const inputValue = e.target.value.trim();
        if (inputValue !== '' && !isNaN(inputValue)) {
            updateCartItemCount(Number(inputValue), productId);
        }
        else {
            updateCartItemCount('', productId);
        }

    }

    return (
        <div className='cart-item'>
            <img src={productImage} alt={productName} />
            <div className='cart-item-des'>
                <h5>{productName}</h5>
                {salePrice ? 
                    <p>Rs.{salePrice}</p>
                    :<p>Rs.{price}</p>
                }
            </div>
            <div className='item-details'>
                <table>
                    <tr>
                        <th>Quantity</th>
                        <th>Total Price</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>
                            <div className='count-handler'>
                                <button onClick={() => removeFromCart(productId)}>-</button>
                                <input value={cartItems[productId]} onChange={(e) => handleChange(e)}
                                    onBlur={(e) => handleBlur(e)} />
                                <button onClick={() => addToCart(productId)}>+</button>
                            </div>
                        </td>
                        <td>{salePrice ? salePrice * cartItems[productId]
                                        : price * cartItems[productId]}
                        </td>
                        <td>
                            <div className='item-remove'>
                                <button onClick={() => clearCartItemCount(productId)}>
                                    <FontAwesomeIcon icon={faTrash} size="lg" />
                                </button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            
        </div>
    )
}

export default CartItem
