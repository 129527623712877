import React, { useContext, useEffect } from 'react';
import "../styles.css";
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { ShopContext } from '../context/Shop-Context';
import "./cart.css";
import CartItem from '../components/CartItem';
import { Link } from 'react-router-dom';

const Cart = () => {
  const { cartItems, products } = useContext(ShopContext);
  const cartItemsCount = Object.values(cartItems).reduce((total, count) => total + count, 0);
  let subtotal = 0;

  useEffect(() =>{
    window.scrollTo(0, 0);
}, []);

for (let i = 0; i < products.length; i++) {
  products[i].salePrice ? 
  subtotal += products[i].salePrice * cartItems[products[i].productId] :
  subtotal += products[i].price * cartItems[products[i].productId];
}

  return (
    <Container fluid>
      <Header />
      <Navigation activePath='/cart' />
      <div className='main'>
        <div className='cart'>
          <h3>Your Cart Items</h3>
          <div className='cart-items'>
            {cartItemsCount === 0 ? <p>Your cart is currently empty!</p> :
              products.map((product) => {
                if (cartItems[product.productId] !== 0) {
                  return <CartItem data={product} />
                }
                return null;
              })
            }
          </div>
          {cartItemsCount > 0 ?
            <div className='subtotal'>
              <p>Subtotal: Rs. {subtotal}/=</p>
            </div>
            : null}
          <div className='cart-button-group'>
            <Link to='/shop'><button>Continue Shopping</button></Link>
            {cartItemsCount > 0 ?
              <Link to='/checkout'><button>Checkout</button></Link>
              : null}
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  )
}

export default Cart
