import React, { useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../components/Header';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { ShopContext } from '../context/Shop-Context';
import "./shop.css";
import "./contact.css";

const Contact = () => {
  const { contactDetails } = useContext(ShopContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid>
      <Header />
      <Navigation activePath='/contact' />
      <div className='main'>
        <div className='contact'>
          <h2>Contact</h2>
          <div className='contact-details'>
            <ul>
              {contactDetails ?
                contactDetails.map(contactDetail => (
                  <li key={contactDetail.method}>
                    {contactDetail.method === 'Facebook' ? (
                      <span>{contactDetail.method}: <a href={contactDetail.contactDetail} target='_blank' rel="noreferrer"><b>Art Paradise Lk</b></a></span>
                    ) : (
                      <span>{contactDetail.method}: <b>{contactDetail.contactDetail}</b></span>
                    )}
                  </li>
                )) : null}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </Container>
  )
}

export default Contact
