import React, { useContext, useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Product from '../components/Product';
import "../styles.css";
import "../pages/shop.css";
import { ShopContext } from '../context/Shop-Context';
import { useParams } from 'react-router-dom';

const Collections = () => {
    const { collectionName } = useParams();
    const { products, loading } = useContext(ShopContext);

    // Filter products based on the collectionName
    const filteredProducts = products.filter(product => product.collection === collectionName);

    useEffect(() =>{
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container fluid>
            <Header />
            <Navigation activePath='/collections' />
            <div className='main'>
                <div className='shop'>
                    <h3>{`Collection: ${collectionName}`}</h3>
                    {loading ? (
                        <div className="text-center mt-5">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <div className='row justify-content-center'>
                            {filteredProducts.length === 0 ? (
                                <p>No products available for this collection</p>
                            ) : (
                                filteredProducts.map(product => (
                                    <div key={product.productId} className='col-sm-6 col-md-4 col-lg-3'>
                                        <Product data={product} />
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </Container>
    )
}

export default Collections;
