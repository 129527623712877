export const getProducts = () => {
    return fetch('https://artparadise.madushiranasooriyaportfolio.com/api/getProducts.php')
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
        return [];
      });
  };

  export const getCollections = () => {
    return fetch('https://artparadise.madushiranasooriyaportfolio.com/api/getCollectionNames.php')
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching data:', error);
        return [];
      });
  };

  export const getProductById = (productId) => {
    return fetch(`https://artparadise.madushiranasooriyaportfolio.com/api/getProductById.php?productId=${productId}`)
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching product data:', error);
        return null;
      });
  };

  export const getProductImagesByProductId = (productId) => {
    return fetch(`https://artparadise.madushiranasooriyaportfolio.com/api/getProductImagesByProductId.php?productId=${productId}`)
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching product data:', error);
        return null;
      });
  };

  export const getShippingCharges = () => {
    return fetch('https://artparadise.madushiranasooriyaportfolio.com/api/getShippingCharges.php')
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching product data:', error);
        return null;
      });
  };

  export const getContactDetails = () => {
    return fetch('https://artparadise.madushiranasooriyaportfolio.com/api/getContactDetails.php')
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching product data:', error);
        return null;
      });
  };

  export const getHomeImages = () => {
    return fetch('https://artparadise.madushiranasooriyaportfolio.com/api/getHomeImages.php')
      .then(response => response.json())
      .catch(error => {
        console.error('Error fetching product data:', error);
        return null;
      });
  };


  export const getNumProducts = async () => {
    try {
      const response = await fetch('https://artparadise.madushiranasooriyaportfolio.com/api/getProducts.php');
      const data = await response.json();
      return data.length;
    } catch (error) {
      console.error('Error fetching data:', error);
      return 0;
    }
  };
  
  