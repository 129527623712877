import React from 'react';
import { Modal } from 'react-bootstrap';

const OrderSuccessAlert = ({ show, handleClose, orderNumber, total }) => {
  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton style={{backgroundColor: 'green', color: 'white'}}>
        <Modal.Title>Order Placed Successfully!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your order number: <b>{orderNumber}</b></p>
        <p>Total Price: <b>Rs.{total}</b></p>
        <p>Please pay the above amount to following bank account. Include the <b>order number</b> in the <b>bank slip</b>.</p>
        <ul className='bank-details'>
          <li>Account number- <b>123400130056789</b></li>
          <li>Peoples bank , Ganemulla Branch</li>
        </ul>
        <p>Send the bank slip via WhatsApp (<b>+94764287002</b>) or email(<b>artparadise20lk@gmail.com</b>)</p>
        <p><i>Order details will be sent to your email.</i></p>
      </Modal.Body>
    </Modal>
  );
};

export default OrderSuccessAlert;