import React, { useContext } from 'react';
import { ShopContext } from '../context/Shop-Context';
import { useNavigate } from 'react-router-dom';

const Product = (props) => {
  const { productId, productName, price, salePrice, productImage } = props.data;
  const { addToCart, cartItems } = useContext(ShopContext);
  const navigate = useNavigate();

  const cartItemAmount = cartItems[productId];

  const navigateToProductDisplay = () => {
    navigate(`/products/${productName}`, { state: { productId: `${productId}` } });
  }

  return (
    <div className='image-group'>
      <img src={productImage} alt={productName} onClick={navigateToProductDisplay}/>
      <h5>{productName}</h5>
      {salePrice ? 
        <p><span className='sale'>Save Rs.{price - salePrice} </span>&nbsp;&nbsp;&nbsp;Rs.<del>{price}</del> {salePrice}</p>
        :<p>Rs.{price}</p>
      }
      <button className='addtocart-button' onClick={() => addToCart(productId)}>
        Add to Cart {cartItemAmount > 0 && <>({cartItemAmount})</>}
      </button>
    </div>
  )
}

export default Product
