import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import "../components/footer.css";

const Footer = () => {
  return (
    <footer className="custom-footer">
      <div>
            <a href="mailto:artparadise20lk@gmail.com" className='footer-link'>
              <FontAwesomeIcon icon={faEnvelope} size="lg" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100091813832224&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className='footer-link'>
                <FontAwesomeIcon icon={faFacebook} size="lg" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100091813832224&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className='footer-link'>
                <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
     </div>
     <p>&copy; 2024 Art Paradise</p>
        
    </footer>
  )
}

export default Footer;
