import React, { useContext, useEffect, useState } from 'react';
import emailjs from "@emailjs/browser";
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import Footer from '../components/Footer';
import "../styles.css";
import { Container } from 'react-bootstrap';
import { ShopContext } from '../context/Shop-Context';
import "../pages/checkout.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import OrderSuccessAlert from '../components/OrderSuccessAlert';
import OrderPlaceErrorAlert from '../components/OrderPlaceErrorAlert';

const Checkout = () => {
    const { cartItems, products, shippingCharges, clearCart } = useContext(ShopContext);
    const [showOrderSummary, setShowOrderSummary] = useState(false);
    const [showOrderSummaryBottom, setShowOrderSummaryBottom] = useState(false);
    const [email, setEmail] = useState('');
    const [district, setDistrict] = useState('');
    const [districtId, setDistrictId] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [address, setAddress] = useState('');
    const [shippingCharge, setShippingCharge] = useState(null);
    /*let subtotal = 0;
    let total = 0;*/
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [orderNumber, setOrderNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const cartItemsCount = Object.values(cartItems).reduce((total, count) => total + count, 0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const toggleOrderSummaryDisplay = () => {
        setShowOrderSummary(!showOrderSummary);
    }

    const toggleOrderSummaryBottomDisplay = () => {
        setShowOrderSummaryBottom(!showOrderSummaryBottom);
    }

    useEffect(() => {
        let calculatedSubtotal = 0;
        for (let i = 0; i < products.length; i++) {
            products[i].salePrice
                ? (calculatedSubtotal +=
                    products[i].salePrice * cartItems[products[i].productId])
                : (calculatedSubtotal +=
                    products[i].price * cartItems[products[i].productId]);
        }

        const calculatedTotal =
            calculatedSubtotal + (shippingCharge ? Number(shippingCharge) : 0);

        setSubtotal(calculatedSubtotal);
        setTotal(calculatedTotal);
    }, [products, cartItems, shippingCharge]);

    /*for (let i = 0; i < products.length; i++) {
        products[i].salePrice ?
            subtotal += products[i].salePrice * cartItems[products[i].productId] :
            subtotal += products[i].price * cartItems[products[i].productId];
    }*/

    const handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'email') {
            setEmail(value);
        }
        else if (name === 'district') {
            const selectedDistrict = value;
            setDistrict(selectedDistrict);
            const selectedShippingChargeObj = shippingCharges.find((charge) => charge.districtName === selectedDistrict);
            setShippingCharge(selectedShippingChargeObj ? selectedShippingChargeObj.charge : null);
            setDistrictId(selectedShippingChargeObj ? selectedShippingChargeObj.districtId : null);

        }
        else if (name === 'fname') {
            setFname(value);
        }
        else if (name === 'lname') {
            setLname(value);
        }
        else if (name === 'address') {
            setAddress(value);
        }
    }

    /*total = subtotal + (shippingCharge ? Number(shippingCharge) : 0);*/


    const placeOrder = async () => {
        const formData = {
            email,
            total,
            fname,
            lname,
            address,
            districtId
        };
        const response = await fetch('https://artparadise.madushiranasooriyaportfolio.com/api/placeOrder.php', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();

    }

    const insertOrderedProducts = async (orderId, productId, amount) => {
        const formData = {
            orderId,
            productId,
            amount
        };
        const response = await fetch('https://artparadise.madushiranasooriyaportfolio.com/api/insertOrderedProducts.php', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();

    }


    const handleOrderNow = async (e) => {
        e.preventDefault();

        try {
            const { orderId, orderNumber } = await placeOrder();

            for (const productId in cartItems) {
                const amount = cartItems[productId];
                if (amount > 0) {
                    await insertOrderedProducts(orderId, productId, amount);
                }
            }
            setOrderNumber(orderNumber);

            const emailParams = {
                email: email,
                fname: fname,
                lname: lname,
                orderNumber: orderNumber,
                total: total
            };

            emailjs.send(
                'service_1d28vz9', // Replace with your EmailJS email service ID
                'template_m378ups', // Replace with your EmailJS email template ID
                emailParams,
                'PyRHPSWuJPybgpxwx' // Replace with your EmailJS user ID
            )
                .then(response => {
                    console.log('Email sent successfully:', response);
                })
                .catch(error => {
                    console.error('Error sending email:', error);
                    alert('Error sending email:', error);
                });

            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error handling order:', error);
            console.log('Error response:', error);
            setErrorMessage(error);
            setShowErrorModal(true);
        }
    };



    const clearFormAndCart = () => {
        setEmail('');
        setDistrict('');
        setFname('');
        setLname('');
        setAddress('');
        setShippingCharge(null);

        clearCart();

        window.location.href = '/';
    };


    return (
        <Container fluid>
            <Header />
            <Navigation />
            <div className='main'>
                <div className='row'>
                    <div className='col-12 d-block d-md-none order-summary'>
                        <table className='order-header'>
                            <tr>
                                <td>
                                    {!showOrderSummary ?
                                        <p onClick={() => toggleOrderSummaryDisplay()}>
                                            Show Order Summary <FontAwesomeIcon icon={faAngleDown} />
                                        </p>
                                        : <p onClick={() => toggleOrderSummaryDisplay()}>
                                            Hide Order Summary <FontAwesomeIcon icon={faAngleUp} />
                                        </p>
                                    }
                                </td>
                                <td>
                                    <strong><big>Rs.{subtotal}</big></strong>
                                </td>
                            </tr>
                        </table>

                        {showOrderSummary ?
                            <>
                                <table className='item-details'>
                                    {products.map((product) => {
                                        if (cartItems[product.productId] !== 0) {
                                            return (
                                                <tr>
                                                    <td>
                                                        <img src={product.productImage} alt={product.productName} />
                                                    </td>
                                                    <td>
                                                        <p><b>{product.productName}</b> x {cartItems[product.productId]}</p>
                                                    </td>
                                                    <td>
                                                        <p className='text-right'>
                                                            Rs.{product.salePrice ?
                                                                product.salePrice * cartItems[product.productId]
                                                                : product.price * cartItems[product.productId]}
                                                        </p>
                                                    </td>
                                                </tr>
                                            )
                                        } else return null
                                    })}
                                </table>
                                <table className='pricing-details'>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>Rs.{subtotal}</td>
                                    </tr>
                                    <tr>
                                        <td>Shipping</td>
                                        <td>Rs.{shippingCharge}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td>Rs. {total}</td>
                                    </tr>
                                </table>
                            </>
                            : null
                        }
                    </div>
                    <div className='col-12 col-md-7 contact-details'>
                        <h3>Your Details</h3>
                        <form onSubmit={handleOrderNow}>
                            <h4>Contact</h4>
                            <div className='form-group'>
                                <label htmlFor='email'>Email</label>
                                <input type='email' name='email' id='email' className='form-control' required
                                    placeholder='abc@gmail.com' value={email} onChange={(e) => handleInputChange(e)} />
                            </div>
                            <br />
                            <h4>Delivery</h4>
                            <div className='form-group'>
                                <label htmlFor='district'>District</label>
                                <select name='district' id='district' className='form-control' required
                                    value={district} onChange={(e) => handleInputChange(e)}>
                                    <option value='' disabled>Select District</option>
                                    {shippingCharges ?
                                        shippingCharges.map((charge) => (
                                            <option key={charge.id} value={charge.districtName}>
                                                {charge.districtName}
                                            </option>
                                        ))
                                        : null}
                                </select>
                            </div>
                            <div className='form-group row'>
                                <div className='col-md-6 f-name'>
                                    <label htmlFor='fname'>First Name</label>
                                    <input type='text' name='fname' id='fname' className='form-control' placeholder='First Name'
                                        value={fname} onChange={(e) => handleInputChange(e)} required />
                                </div>
                                <div className='col-md-6 l-name'>
                                    <label htmlFor='lname'>Last Name</label>
                                    <input type='text' name='lname' id='lname' className='form-control' placeholder='Last Name'
                                        value={lname} onChange={(e) => handleInputChange(e)} required />
                                </div>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='address'>Address</label>
                                <input type='text' name='address' id='address' className='form-control' placeholder='Address'
                                    value={address} onChange={(e) => handleInputChange(e)} required />
                            </div>
                            <div className='col-12 d-block d-md-none order-summary summary-bottom'>
                                <table className='order-header'>
                                    <tr>
                                        <td>
                                            <h3 onClick={() => toggleOrderSummaryBottomDisplay()}>Order Summary ({cartItemsCount})</h3>
                                        </td>
                                        <td>
                                            {!showOrderSummaryBottom ?
                                                <p onClick={() => toggleOrderSummaryBottomDisplay()}>
                                                    Show <FontAwesomeIcon icon={faAngleDown} />
                                                </p>
                                                : <p onClick={() => toggleOrderSummaryBottomDisplay()}>
                                                    Hide <FontAwesomeIcon icon={faAngleUp} />
                                                </p>
                                            }
                                        </td>
                                    </tr>
                                </table>

                                {showOrderSummaryBottom ?
                                    <>
                                        <table className='item-details'>
                                            {products.map((product) => {
                                                if (cartItems[product.productId] !== 0) {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <img src={product.productImage} alt={product.productName} />
                                                            </td>
                                                            <td>
                                                                <p><b>{product.productName}</b> x {cartItems[product.productId]}</p>
                                                            </td>
                                                            <td>
                                                                <p className='text-right'>
                                                                    Rs.{product.salePrice ?
                                                                        product.salePrice * cartItems[product.productId]
                                                                        : product.price * cartItems[product.productId]}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                } else return null
                                            })}
                                        </table>
                                    </>
                                    : null
                                }
                                <table className='pricing-details'>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td>Rs.{subtotal}</td>
                                    </tr>
                                    <tr>
                                        <td>Shipping</td>
                                        <td>Rs.{shippingCharge}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td>Rs. {total}</td>
                                    </tr>
                                </table>
                            </div>
                            <div className='form-group'>
                                <button className='form-control' >Order Now</button>
                            </div>

                        </form>
                    </div>
                    <div className='col-12 col-md-5 d-none d-md-block cart-details fixed'>
                        <div className='sticky-cart-details'>
                            {
                                products.map((product) => {
                                    if (cartItems[product.productId] !== 0) {

                                        return (
                                            <div className='row item-details'>
                                                <div className='col-3'>
                                                    <img src={product.productImage} alt={product.productName} />
                                                </div>
                                                <div className='col-5'>
                                                    <p><b>{product.productName}</b> x {cartItems[product.productId]}</p>
                                                </div>
                                                <div className='col-4'>
                                                    <p className='text-right pr-5'>Rs.{product.price * cartItems[product.productId]}</p>
                                                </div>

                                            </div>)
                                    }
                                    return null;
                                })
                            }
                            <div className='row item-details mt-5 mb-3'>
                                <div className='col-8 pl-4'><p>Subtotal</p></div>
                                <div className='col-4'><p className='text-right pr-5'><b>Rs.{subtotal}</b></p></div>
                                <div className='col-8 pl-4'><p>Shipping</p></div>
                                <div className='col-4'>
                                    <p className='text-right pr-5'>{shippingCharge ? "Rs." + shippingCharge : null}</p>
                                </div>
                                <div className='col-8 pl-4'><p><big><b>Total</b></big></p></div>
                                <div className='col-4'><p className='text-right pr-5'><big><b>Rs.{total}</b></big></p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <OrderSuccessAlert show={showSuccessModal}
                handleClose={() => {
                    setShowSuccessModal(false);
                    clearFormAndCart();
                }}
                orderNumber={orderNumber} total={total} />

            <OrderPlaceErrorAlert show={showErrorModal} handleClose={() => setShowErrorModal(false)}
                errorMessage={errorMessage} />
        </Container>
    )
}

export default Checkout
