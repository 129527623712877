import React, { useContext } from "react";
import { Navbar, Nav, Row, Col, NavDropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import { ShoppingCart } from "phosphor-react";
import logo from '../images/logo.jpeg';
import "./navigation.css";
import { ShopContext } from "../context/Shop-Context";

const Navigation = (props) => {
  const { cartItems, collections } = useContext(ShopContext);
  const cartItemsCount = Object.values(cartItems).reduce((total, count) => total + count, 0);


  return (
    <>
      <div className="sticky-top">
        {/* Large Screens */}
        <Navbar variant="dark" expand="lg" className="navbar-dark d-none d-lg-block ">
          <Row className="w-100">
            <Col xs={2} className="d-flex justify-content-start">
              <Link to="/" className="logo-link">
                <img src={logo} width="70px" height="70px" alt="logo.jpg" />
                <h4>Art Paradise</h4>
              </Link>
            </Col>
            <Col xs={8} className="d-flex justify-content-center align-items-center">
              <Navbar variant="light" expand="lg" className="navbar-dark d-none d-lg-block">
                <Nav className="d-flex justify-content-center align-items-center">
                  <Link to="/" className="nav-link">
                    <span className={`navbar-link ${props.activePath === '/' ? 'active-link' : ''}`}>HOME</span>
                  </Link>
                  <Link to="/shop" className="nav-link">
                    <span className={`navbar-link ${props.activePath === '/shop' ? 'active-link' : ''}`}>SHOP</span>
                  </Link>
                  <NavDropdown 
                    title={<span className={`navbar-link ${props.activePath === '/collections' ? 'active-link' : ''}`}>COLLECTIONS</span>} 
                    id="basic-nav-dropdown">
                    {collections.map((collection, index) => (
                      <NavDropdown.Item key={index}
                        href={`/collections/${collection.collection}`} className="dropdown-item" >
                        {collection.collection}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                  <Link to="/about" className="nav-link">
                    <span className={`navbar-link ${props.activePath === '/about' ? 'active-link' : ''}`}>ABOUT</span>
                  </Link>
                  <Link to="/contact" className="nav-link">
                    <span className={`navbar-link ${props.activePath === '/contact' ? 'active-link' : ''}`}>CONTACT</span>
                  </Link>
                </Nav>
              </Navbar>
            </Col>
            <Col xs={2} className="d-flex justify-content-end align-items-center">
              <Link to="/cart" className="nav-link">
                <div className={`navbar-link d-flex align-items-center ${props.activePath === '/cart' ? 'active-link' : ''}`}>
                  <span>MY CART</span>
                  <span className="ml-2">
                    <ShoppingCart size={30} />
                    <sup className="cart-count">
                      {typeof cartItemsCount === 'number' ? cartItemsCount : ''}
                    </sup>
                  </span>
                </div>
              </Link>
            </Col>
          </Row>
        </Navbar>

        {/* Small Screens */}
        <Navbar variant="dark" expand="lg" className="navbar-dark d-lg-none d-flex align-items-center">
          <Row className="w-100">
            <Col xs={2} className="d-flex justify-content-start align-items-center">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Col>
            <Col xs={8} className="d-flex justify-content-center">
              <Link to="/" className="logo-link">
                <img src={logo} width="70px" height="70px" alt="logo.jpg" />
              </Link>
            </Col>
            <Col xs={2} className="d-flex justify-content-end align-items-center">
              <Link to="/cart" className="nav-link">
                <span className={`navbar-link ${props.activePath === '/cart' ? 'active-link' : ''}`}>
                  <ShoppingCart size={30} /><sup className="cart-count">{cartItemsCount}</sup>
                </span>
              </Link>
            </Col>
          </Row>
          <Navbar.Collapse id="basic-navbar-nav" className="navbar-dark">
            <Nav className="d-flex flex-column align-items-start">
              <Link to="/" className="nav-link">
                <span className={`navbar-link ${props.activePath === '/' ? 'active-link' : ''}`}>HOME</span>
              </Link>
              <Link to="/shop" className="nav-link">
                <span className={`navbar-link ${props.activePath === '/shop' ? 'active-link' : ''}`}>SHOP</span>
              </Link>
              <NavDropdown 
                title={<span className={`navbar-link ${props.activePath === '/collections' ? 'active-link' : ''}`}>COLLECTIONS</span>} 
                id="basic-nav-dropdown">
                {collections.map((collection, index) => (
                  <NavDropdown.Item key={index}
                    href={`/collections/${collection.collection}`} className="dropdown-item" >
                    {collection.collection}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <Link to="/about" className="nav-link">
                <span className={`navbar-link ${props.activePath === '/about' ? 'active-link' : ''}`}>ABOUT</span>
              </Link>
              <Link to="/contact" className="nav-link">
                <span className={`navbar-link ${props.activePath === '/contact' ? 'active-link' : ''}`}>CONTACT</span>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  );
};

export default Navigation;
